import React from 'react'
import axios from 'axios'
import { API_BASE_URL} from '../constants';

class AuthenticationService{
 
    login(loginRequest) {
        return axios
          .post(API_BASE_URL + "api/auth/signin",loginRequest)
          .then(response => {
            console.log(response)
            if (response.data) {
                sessionStorage.setItem("user", JSON.stringify(response.data));
            }
    
            return response.data;
          });
      }
    resetPasswordRequest(resetPasswordRequest) {
        return axios.post(API_BASE_URL + `api/auth/resetPasswordRequest/${resetPasswordRequest.email}`)
      }
    
      resetPassword(resetPassword) {
        return axios.post(API_BASE_URL + `api/auth/resetPassword/${resetPassword.password}/${resetPassword.token}`)
      }
    
      verifyEmail(token){
        return axios.post(API_BASE_URL + `api/auth/verifyEmail/${token}`)
    
      }
      socialSignIn(loginRequest){
        return axios
          .post(API_BASE_URL + "api/auth/signin", loginRequest)
          .then(response => {
            if (response.data.accessToken) {
                sessionStorage.setItem("user", JSON.stringify(response.data));
            }
    
            return response.data;
          });
      }
      socialSignUp(signUpRequest){
        return axios.post(API_BASE_URL + "api/auth/signup", 
          signUpRequest
        );
      }
      register(signUpRequest) {
        return axios.post(API_BASE_URL + "api/auth/signup", signUpRequest);
      }
    logout(){
        sessionStorage.removeItem('user')
        return true
    }
    isUserLoggedIn(){
        let user = sessionStorage.getItem('user')
        if(user===null) return false 
            return true
    }
    getLoggedInUser(){
        let user = sessionStorage.getItem('user')
        if(user===null) return ''
            return user
    }
    getLoggedInUserRole(){
      let user = JSON.parse(sessionStorage.getItem('user'))
      if(user===null) return ''
          return user.role;
  }
    // setUpAxiosInterceptors(basicAuth){
       
    //     axios.interceptors.request.use(
    //         (config) => {
    //             if(this.isUserLoggedIn()){
    //                 config.headers.authorization = basicAuth
    //             }
    //             return config
    //         }
    //     )
    // }
    getAdvertiserId(){
      let user = JSON.parse(sessionStorage.getItem('user'))
      if(user===null) return null
          return user.id;

  }

}
export default new AuthenticationService()