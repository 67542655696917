export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://admin.influencedigi.com/InfAdminServer/';
export const PROFILE_IMAGE_URL = 'https://influencedigi.com/InfUserServer/getUserProfile';
export const CORP_IMAGE_URL = 'https://influencedigi.com/InfUserServer/getUserCorpPic';
export const VIDEO_DOWNLOAD_URL = 'https://influencedigi.com/InfUserServer/downloadVideo/';
export const USERNAME = 'admin';
export const PASSWORD = 'admin';

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;
