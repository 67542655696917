import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import AuthenticatedRoute from './common/AuthenticationRoute';
import AdvertiserAuthenticatedRoute from './common/AdvertiserAuthenticateRoute';



const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./Login'));
const ForgotPassword = React.lazy(() => import('./Login/ForgotPassword'));
const ResetPassword = React.lazy(()=> import('./Login/ResetPassword'))
const Register = React.lazy(() => import('./Register/Register'));
const CreateProfile = React.lazy(() => import('./components/Profile/CreateProfile'));





class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
           
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />

              <Route exact path="/forgotPassword" name="Forgot Password" render={props => <ForgotPassword {...props}/>} />
              
              <Route exact path="/resetPassword/" name="Forgot Password" render={props => <ResetPassword {...props}/>} />

              <AuthenticatedRoute path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
